<template>
  <div class="lists__list-shape">
    <div class="shape__inner">
      <router-link
        class="project__item scrollObj"
        v-for="(project, index) in lists"
        :key="index"
        tag="div"
        :to="{
          path: `/project/${project.id}`
        }"
      >
        <div class="item__inner">
          <div class="animation__line scrollObj"></div>
          <div class="item__main-body">
            <div class="item__cover scrollObj">
              <img :src="project.covers[0]" alt="" />
            </div>
            <div class="item__info scrollObj">
              <div>
                <p class="en">{{ project.name.en }}</p>
                <p class="cn">{{ project.name.cn }}</p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c__views-list-shape',
  props: ['lists'],
};
</script>

<style></style>
