<template>
  <div class="project__container">
    <div class="project__menus-container">
      <div class="menu__item" v-for="(item, index) in menus" :key="index">
        <div class="item__link">{{ item.name }}</div>
      </div>
    </div>
    <div class="project__lists-container">
      <div class="lists__inner">
        <index-shape v-if="currentShape === 'index'" :lists="lists"></index-shape>
        <list-shape v-if="currentShape === 'list'" :lists="lists2"></list-shape>
      </div>
    </div>
    <router-link
      class="info__switch__bt normal"
      :to="{
        path: '/projects'
      }"
    >
      <div class="icon__wrapper">
        <svg class="bt__icon" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
          <rect fill="#000" width="2" height="2"></rect>
          <rect fill="#000" x="5" width="2" height="2"></rect>
          <rect fill="#000" x="10" width="2" height="2"></rect>
          <rect fill="#000" y="5" width="2" height="2"></rect>
          <rect fill="#000" x="5" y="5" width="2" height="2"></rect>
          <rect fill="#000" x="10" y="5" width="2" height="2"></rect>
          <rect fill="#000" y="10" width="2" height="2"></rect>
          <rect fill="#000" x="5" y="10" width="2" height="2"></rect>
          <rect fill="#000" x="10" y="10" width="2" height="2"></rect>
        </svg>
      </div>
    </router-link>
  </div>
</template>

<script>
/* eslint-disable global-require */
import indexShape from './_index.vue';
import listShape from './_list.vue';

export default {
  name: 'views-project-shape-list',
  components: {
    indexShape,
    listShape,
  },
  data() {
    return {
      menus: [
        {
          name: 'sales center',
        },
        {
          name: 'residence',
        },
        {
          name: 'office',
        },
        {
          name: 'hotel&club',
        },
        {
          name: 'entire case custom',
        },
      ],
      lists: [],
      lists2: [],
      currentShape: 'list',
    };
  },
  methods: {},
};
</script>

<style></style>
