var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lists__index-shape"},_vm._l((_vm.lists),function(project,index){return _c('router-link',{key:index,ref:"items",refInFor:true,staticClass:"project__item",class:{
      'mobile-one-full': project.covers.length === 1
    },attrs:{"tag":"div","to":{
      path: ("/project/" + (project.id))
    }}},[_c('div',{staticClass:"project__title scrollObj",class:{
        pcStyle: !_vm.$bowser.mobile
      }},[_c('span',[_vm._v(_vm._s(project.name.en))]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("| "+_vm._s(project.name.cn))])]),_c('div',{staticClass:"project__imgs",class:[project.cover_position, _vm.$bowser.mobile ? 'mobile' : 'pc']},_vm._l((project.covers),function(img,i){return _c('div',{key:i,staticClass:"img__box scrollObj",class:{
          pcHover: !_vm.$bowser.mobile
        },on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_c('img',{attrs:{"data-src":img,"src":img,"alt":""}})])}),0)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }