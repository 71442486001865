<template>
  <div class="lists__index-shape">
    <router-link
      class="project__item"
      v-for="(project, index) in lists"
      ref="items"
      tag="div"
      :key="index"
      :to="{
        path: `/project/${project.id}`
      }"
      :class="{
        'mobile-one-full': project.covers.length === 1
      }"
    >
      <div
        class="project__title scrollObj"
        :class="{
          pcStyle: !$bowser.mobile
        }"
      >
        <span>{{ project.name.en }}</span>
        <span style="margin-left:5px;">|&nbsp;{{ project.name.cn }}</span>
      </div>
      <div
        class="project__imgs"
        :class="[project.cover_position, $bowser.mobile ? 'mobile' : 'pc']"
      >
        <div
          class="img__box scrollObj"
          v-for="(img, i) in project.covers"
          :key="i"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
          :class="{
            pcHover: !$bowser.mobile
          }"
        >
          <img :data-src="img" :src="img" alt="" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'c__views-index-shape',
  props: ['lists'],
  data() {
    return {
      imgsMap: new Map(),
    };
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      that.pageInit();
      window.addEventListener('resize', that.firstImgOffsetLeft);
    });
  },
  destoryed() {
    const that = this;
    window.removeEventListener('resize', that.firstImgOffsetLeft, true);
  },
  directives: {
    imgsize: {
      bind(el, { value }) {
        console.log(value);
      },
    },
  },
  methods: {
    firstImgOffsetLeft() {
      this.initPosLeft();
    },
    pageInit() {
      const that = this;
      let imgs = [];
      that.lists.forEach((item) => {
        imgs = imgs.concat(item.covers);
      });
      const pArr = imgs.map(
        (img) => new Promise((resolve) => {
          const image = new Image();
          image.onload = function () {
            that.imgsMap.set(image.src, {
              width: image.width,
              height: image.height,
            });
            resolve(image);
          };
          image.src = img;
        }),
      );
      Promise.all(pArr).then(() => {
        that.$emit('imgLoaded');
        that.$refs.items.forEach((item) => {
          const obj = item.$el;
          const $imgs = obj.querySelectorAll('.img__box');
          if ($imgs.length === 2) {
            // const src1 = $imgs['0'].querySelector('img').src;
            // const src2 = $imgs['1'].querySelector('img').src;
            // const info1 = that.imgsMap.get(src1);
            // const info2 = that.imgsMap.get(src2);

            // $imgs.querySelector(`img[data-src="${src1}"]`).style.width = that.px2vw(info1.width);
            // $imgs.querySelector(`img[data-src="${src2}"]`).style.width = that.px2vw(info2.width);

            $imgs.forEach(($img) => {
              const { src } = $img.querySelector('img');
              const info = that.imgsMap.get(src);
              // eslint-disable-next-line no-param-reassign
              $img.querySelector('img').style.width = that.px2vw(info.width);
            });

            // if (info1.height === info2.height) {
            //   obj.querySelector('.project__imgs').classList.add('same-height');
            // }
          }
        });
        that.initPosLeft();
      });
    },
    px2vw(px) {
      const d = this.$bowser.mobile ? 750 : 1920;
      return `${(px / d) * 100}vw`;
    },
    initPosLeft() {
      const that = this;
      let isSetOffset = false;
      that.$refs.items.forEach((item) => {
        const obj = item.$el;
        const $imgs = obj.querySelectorAll('.img__box');
        const $img = $imgs['0'];
        const $title = obj.querySelector('.project__title');
        const imgRectInfo = $img.getBoundingClientRect();
        if (
          obj.classList.contains('mobile-one-full')
          && obj.querySelector('.project__imgs').classList.contains('right')
          && !isSetOffset
        ) {
          that.$parent.$emit('firstImgOffsetLeft', imgRectInfo.x);
          isSetOffset = true;
        }
        $title.style.left = `${imgRectInfo.x - 60}px`;
      });
      if (isSetOffset === false) {
        that.$parent.$emit('firstImgOffsetLeft', 0);
      }
    },
    onMouseEnter(el) {
      el.currentTarget.parentNode.parentNode.classList.add('showTitle');
    },
    onMouseLeave(el) {
      el.currentTarget.parentNode.parentNode.classList.remove('showTitle');
    },
  },
};
</script>

<style></style>
